import { BooleanField, Datagrid, DateField, List, NumberField, TextField } from 'react-admin';

export const PaymentmethodList = () => (
    <List>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <BooleanField source="active" />
            <TextField source="id" />
            <DateField source="createdAt" />
            <TextField source="paymentProvider" />
            <TextField source="type" />
            <TextField source="picture" />
            <TextField source="description" />
            <NumberField source="fixedFee" />
            <NumberField source="commission" />
            <NumberField source="max" />
            <NumberField source="min" />
            <TextField source="name" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);