import { ApolloError, gql } from '@apollo/client';
import { apolloClient } from './apolloClient';

const SIGN_IN = gql`
  query SingIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password){
        session {
            accessToken
            refreshToken
        }
    }
  }
`;

const client = apolloClient;

export const authProvider = {
    // called when the user attempts to log in
    login: async ({ username, password }: { username: string, password: string }) => {
        try {
            const res = await client.query({
                query: SIGN_IN, variables: {
                    email: username,
                    password,
                }
            });
            localStorage.setItem("token", res.data.signIn.session.accessToken);
            return Promise.resolve();
        } catch (e) {
            return Promise.reject('Invalid login. Please try agian.');
        }
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    // check if there is any error in response
    checkError: (error: ApolloError) => {
        if (error.graphQLErrors && error.graphQLErrors[0].extensions?.code === 'invalid-jwt') {
            localStorage.removeItem("token");
            return Promise.reject();
        }

        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem("token")
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};