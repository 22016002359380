import { BooleanField, Datagrid, DateField, List, NumberField, ReferenceField, TextField, TextInput } from 'react-admin';

const assetFilters = [
    <TextInput label="Search by name" source="name@_like" alwaysOn />,
    <TextInput label="Search by Id" source="id" alwaysOn />,
];

export const AssetList = () => (
    <List filters={assetFilters}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <BooleanField source="active" />
            <NumberField source="sortIndex" />
            <TextField source="name" />
            <TextField source="shortName" />
            <TextField source="fullName" />
            <TextField source="symbol" />
            <TextField source="logo" />
            <TextField source="exchange" />
            <TextField source="marketType" />
            <ReferenceField source="providerId" reference="providers" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);