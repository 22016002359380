import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect } from "react";

export const EnvSelect = () => {
    const env = localStorage.getItem('env');

    useEffect(() => {
        if (!env) {
            localStorage.setItem('env', 'staging');
        }
    }, [env]);

    const handleChange = (event: SelectChangeEvent) => {
        localStorage.setItem('env', event.target.value);
        localStorage.removeItem('token');
        window.location.reload();
    };

    return (
        <div style={{
            position: 'fixed',
            left: 20,
            bottom: 20,
            zIndex: 2,
            backgroundColor: 'white',
            padding: 20,
            borderRadius: 10,
        }}>
            <strong>environment: </strong>
            <Select value={env ? env : ''} onChange={handleChange}>
                <MenuItem value='dev'>Dev</MenuItem>
                <MenuItem value='staging'>Staging</MenuItem>
                <MenuItem value='production'>Production</MenuItem>
            </Select>
        </div>
    );
}


