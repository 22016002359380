import { DateField, Edit, ReferenceField, SelectInput, SimpleForm, SimpleShowLayout, TextField } from 'react-admin';

export const AuthUserRolesEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '50%' }}>
            <SelectInput label="Role" source="role" alwaysOn choices={[
                { id: 'viewer', name: 'viewer' },
                { id: 'user', name: 'user' },
            ]} />
            <SimpleShowLayout>
                <DateField source="createdAt" fullWidth />
                <TextField source="id" fullWidth />
                <ReferenceField source="userId" reference="users" fullWidth />
            </SimpleShowLayout>

        </SimpleForm>
    </Edit>
);