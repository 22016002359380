import { Datagrid, DateField, DateTimeInput, List, ReferenceField, SelectInput, TextField } from 'react-admin';

const AuthuserproviderFilters = [
    <DateTimeInput label="From" source="createdAt@_gte" alwaysOn />,
    <DateTimeInput label="To" source="createdAt@_lte" alwaysOn />,
    <SelectInput label="Search by provider" source="providerId" alwaysOn choices={[
        { id: 'google', name: 'google' },
        { id: 'apple', name: 'apple' },
        { id: 'facebook', name: 'facebook' },
    ]} />
];

export const AuthuserproviderList = () => (
    <List filters={AuthuserproviderFilters}>
        <Datagrid>
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <TextField source="id" />
            <TextField source="providerId" />
            <ReferenceField source="userId" reference="users" />
        </Datagrid>
    </List >
);