import Grid from '@mui/material/Grid/Grid';
import { DateField, Edit, NumberField, ReferenceField, ReferenceManyField, SelectInput, SimpleForm, SimpleShowLayout, SingleFieldList, TextField, TextInput, WrapperField } from 'react-admin';
import { AgreementLink } from './AgreementLink';

export const OrderEdit = (props: any) => {

    return (
        <Edit>

            <SimpleForm style={{ width: '50%' }}>

                <SelectInput source="status" fullWidth choices={[
                    { id: 'PENDING', name: 'PENDING' },
                    { id: 'REJECTED', name: 'REJECTED' },
                    { id: 'ACCEPTED', name: 'ACCEPTED' },
                    { id: 'REFUNDED', name: 'REFUNDED' },
                    { id: 'BUY_BACKED', name: 'BUY_BACKED', disabled: true },
                    { id: 'CONFIRMED', name: 'CONFIRMED', disabled: true },
                    { id: 'DECLINED', name: 'DECLINED', disabled: true },
                    { id: 'FILLED', name: 'FILLED', disabled: true },
                    { id: 'DRAFT', name: 'DRAFT', disabled: true },
                ]} />
                <TextInput label="Comment for the user" source="notes" fullWidth />
                <Grid container>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <TextField label="Order Id" source="id" />
                            <ReferenceField source="accountId" reference="accounts" />
                            <DateField source="createdAt" label="Date" />
                            <DateField source="createdAt" label="Time" showTime showDate={false} />
                            <TextField source="side" />

                            <ReferenceManyField label="Transfers" target="orderId" reference="transfers">
                                <SingleFieldList>
                                    <TextField source="id" />
                                </SingleFieldList>
                            </ReferenceManyField>

                            <ReferenceManyField label="Provider" target="orderId" reference="transfers">
                                <SingleFieldList>
                                    <TextField source="provider" />
                                </SingleFieldList>
                            </ReferenceManyField>

                            <ReferenceField label="First Name" reference="accounts" source="accountId">
                                <ReferenceField label="First Name" reference="users" source="userId">
                                    <TextField source="metadata.firstName" />
                                </ReferenceField>
                            </ReferenceField>

                            <ReferenceField label="Last Name" reference="accounts" source="accountId">
                                <ReferenceField label="Last Name" reference="users" source="userId">
                                    <TextField source="metadata.lastName" />
                                </ReferenceField>
                            </ReferenceField>

                            <ReferenceField label="Email" reference="accounts" source="accountId">
                                <ReferenceField label="Email" reference="users" source="userId">
                                    <TextField source="email" />
                                </ReferenceField>
                            </ReferenceField>

                            <ReferenceField label="Country of residence" reference="accounts" source="accountId">
                                <ReferenceField label="Country of residence" reference="users" source="userId">
                                    <TextField source="metadata.citizenship.name" />
                                </ReferenceField>
                            </ReferenceField>

                            <ReferenceField label="SPV Name" source="spvId" reference="SPVs">
                                <TextField source="name" />
                            </ReferenceField>



                        </SimpleShowLayout>

                    </Grid>
                    <Grid item xs={6}>
                        <SimpleShowLayout>

                            <ReferenceField label="Asset Name" source="spvId" reference="SPVs">
                                <ReferenceField label="Asset Name" reference="assets" source="assetId">
                                    <TextField source="name" />
                                </ReferenceField>
                            </ReferenceField>

                            <NumberField label="Notional" source="notional" />
                            <NumberField label="Bonus used" source="bonusUsed" />
                            <NumberField label="Commision" source="commission" />
                            <NumberField label="Overall Charged" source="overallCharged" />


                            <NumberField label="Number of shares_Asset" source="amount" />
                            <NumberField label="Number of shares_SPV" source="amountSharesOfSPV" />

                            <ReferenceField label="Max Investment" source="spvId" reference="SPVs">
                                <TextField source="maxInvestment" />
                            </ReferenceField>

                            <ReferenceField label="Min Investment" source="spvId" reference="SPVs">
                                <TextField source="minInvestment" />
                            </ReferenceField>

                            <TextField source="managementFee" />

                            <ReferenceField source="relatedOrderId" reference="orders" />

                            <DateField source="updatedAt" />

                            <WrapperField source="agreementId">
                                <AgreementLink />
                            </WrapperField>


                        </SimpleShowLayout>
                    </Grid>
                </Grid>

            </SimpleForm>
        </Edit>
    );
}