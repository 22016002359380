import { DateField, Edit, ReferenceField, SimpleForm, SimpleShowLayout, TextField, } from 'react-admin';

export const AccountEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '50%' }}>
            <SimpleShowLayout>
                <ReferenceField source="userId" reference="users">
                    <TextField source="displayName" />
                </ReferenceField>
                <TextField source="id" />
                <TextField source="status" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </SimpleShowLayout>
        </SimpleForm>
    </Edit>
);