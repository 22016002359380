export function lowerFirst(s: string) {
    if (s.length < 1) return s
    return s.charAt(0).toLowerCase() + s.substring(1)
}

export function upperFirst(s: string) {
    if (s.length < 1) return s
    return s.charAt(0).toUpperCase() + s.substring(1)
}

export function createAggregationQuery(resourceName: string) {
    return `${resourceName}Aggregate`
}
