
import { Create, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin';
import { JsonInput } from "react-admin-json-view";

export const CompanyProfileCreate = () => (
    <Create>
        <SimpleForm style={{ width: '50%' }}>
            <ReferenceInput label="Name" source="assetId" reference="assets">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="country" fullWidth />
            <NumberInput source="marketCapitalization" fullWidth />
            <JsonInput source="data" reactJsonOptions={{
                name: null,
                collapsed: true,
                enableClipboard: false,
                displayDataTypes: false,
            }} />
            <TextField source="currency" fullWidth />
        </SimpleForm>
    </Create>
);