import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
    Form,
    required,
    TextInput,
    useLogin,
    useNotify,
} from 'react-admin';

import Box from '@mui/material/Box';
import { EnvSelect } from '../env/EnvSelect';

const Login = () => {
    const [loading, setLoading] = useState(false);

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === 'string' ?
                    error : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                {
                    type: 'error',
                    messageArgs: {
                        _: error.message,
                    },
                }
            );
        });
    };

    return (
        <>
            <Form onSubmit={handleSubmit} noValidate>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        background: '#1c191d',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                >
                    <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                        <Box
                            sx={{
                                margin: '1em',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar sx={{ bgcolor: 'secondary.main' }}>
                                <LockIcon />
                            </Avatar>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '1em',
                                display: 'flex',
                                justifyContent: 'center',
                                color: theme => theme.palette.grey[500],
                            }}
                        >
                        </Box>
                        <Box sx={{ padding: '0 1em 1em 1em' }}>
                            <Box >
                                <TextInput
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus
                                    source="username"
                                    label="Username"
                                    disabled={loading}
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                            <Box >
                                <TextInput
                                    source="password"
                                    label="Password"
                                    type="password"
                                    disabled={loading}
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                        <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                SIGN IN
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            </Form>
            <EnvSelect />
        </>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
    username?: string;
    password?: string;
}
