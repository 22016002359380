export const EnvDisplay = () => {
    const env = localStorage.getItem('env');

    return (
        <div style={{
            position: 'fixed', left: 20, bottom: 20, zIndex: 1,
        }}>
            <strong>environment: {env}</strong>
        </div>
    );
}


