import { BooleanField, Datagrid, DateField, DateTimeInput, FunctionField, List, NumberField, ReferenceField, TextField, TextInput, UrlField } from 'react-admin';


const newsFilters = [
    <DateTimeInput label="From (Date)" source="date@_gte" alwaysOn />,
    <DateTimeInput label="To (Date)" source="date@_lte" alwaysOn />,
    <TextInput label="Search by Id" source="id" alwaysOn />,
    <TextInput label="Search by Headline" source="headline" alwaysOn />,
    <TextInput label="Search by Company Name" source="companyName" alwaysOn />,

];
export const NewsList = () => (
    <List filters={newsFilters} sort={{ field: 'date', order: 'DESC' }}>
        <Datagrid rowClick="edit" >
            <TextField source="id" />
            <ReferenceField source="assetId" reference="assets" />

            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <DateField source="date" />

            <TextField source="companyName" />

            <BooleanField source="companyNameMatch" />

            <FunctionField label="content" render={(record: { content: string; }) => record.content ? record.content.substring(0, 20) + '...' : ''} />
            <FunctionField label="longSummary" render={(record: { longSummary: string; }) => record.longSummary ? record.longSummary.substring(0, 20) + '...' : ''} />
            <FunctionField label="mediumSummary" render={(record: { mediumSummary: string; }) => record.mediumSummary ? record.mediumSummary.substring(0, 20) + '...' : ''} />
            <FunctionField label="shortSummary" render={(record: { shortSummary: string; }) => record.shortSummary ? record.shortSummary.substring(0, 20) + '...' : ''} />

            <FunctionField label="quote" render={(record: { quote: string; }) => record.quote ? record.quote.substring(0, 20) + '...' : ''} />
            <TextField source="quoteAuthor" />

            <TextField source="number" />
            <FunctionField label="numberDescription" render={(record: { numberDescription: string; }) => record.numberDescription ? record.numberDescription.substring(0, 20) + '...' : ''} />

            <FunctionField label="headline" render={(record: { headline: string; }) => record.headline ? record.headline.substring(0, 20) + '...' : ''} />

            <FunctionField label="image" render={(record: { image: string; }) => record.image ? record.image.substring(0, 20) + '...' : ''} />


            <NumberField source="computed_general_shares" />
            <NumberField source="computed_instagram_story_shares" />
            <NumberField source="computed_likes" />


            <NumberField source="initialGeneralShares" />
            <NumberField source="initialInstagramStoryShares" />
            <NumberField source="initialLikes" />



            <BooleanField source="restrictedTopicIndicator" />


            <TextField source="svg" />
            <TextField source="template" />
            <TextField source="colorScheme" />

            <TextField source="source" />
            <UrlField source="url" />
        </Datagrid>
    </List>
);