import { useEffect, useState } from 'react';
import { useCreatePath, useDataProvider, useRecordContext } from 'react-admin';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

export const OrdersForUser = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const createPath = useCreatePath();
    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);

    const onRowClick = (row: any) => {
        const orderLinkPath = createPath({ resource: 'orders', type: 'show', id: row.id });
        navigate(orderLinkPath);
    }

    const getOrders = async () => {
        try {
            let accountIds: Array<string> = [];

            // get accounts first
            await dataProvider.getList('accounts', {
                sort: { field: 'createdAt', order: 'DESC' },
                pagination: { page: 1, perPage: 1000 },
                filter: { userId: record.id },
            }).then((response: any) => {
                console.log(response);
                response.data.forEach((accounts: { id: string; }) => {
                    accountIds.push(accounts.id);
                });
            });

            await dataProvider.getList('orders', {
                sort: { field: 'createdAt', order: 'DESC' },
                pagination: { page: 1, perPage: 1000 },
                filter: { accountId: accountIds }, // pass accountIds here
            }).then((response: any) => {
                setOrders(response.data.map((order: {
                    notional: any;
                    createdAt: any;
                    id: any;
                    side: any;
                    status: any;
                }) => {
                    return {
                        id: order.id,
                        col1: order.createdAt,
                        col2: order.side,
                        col3: order.status,
                        col4: order.notional,
                    }
                }));
            });

        } catch (error: any) {
            console.log(error);
        }
    }

    useEffect(() => {
        getOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <DataGrid
            style={{ width: '100%' }}
            rows={orders}
            autoHeight
            columns={[
                { field: 'id', headerName: 'Order Id', flex: 1 },
                {
                    field: 'col1',
                    headerName: 'Date Time',
                    flex: 1,
                    valueGetter: (value: any) => {
                        const date = new Date(value);
                        return format(date, 'MM/dd/yyyy, HH:mm');
                    },
                },
                { field: 'col2', headerName: 'Side', flex: 1 },
                { field: 'col3', headerName: 'Status', flex: 1 },
                { field: 'col4', headerName: 'Notional', flex: 1 },
            ]}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
            }}
            onRowClick={(row) => onRowClick(row)}
            sx={{
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer', // Apply pointer cursor on row hover
                },
            }}
        />
    );
}