import { Grid } from '@mui/material';
import { DateField, Edit, NumberField, NumberInput, ReferenceField, SelectInput, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';

export const UserOptionsEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '50%' }}>
            <SelectInput label="Check status" source="checkStatus" alwaysOn choices={[
                { id: 'NOT_CHECKED', name: 'NOT_CHECKED' },
                { id: 'KYC_CHECK_SUCCESS', name: 'KYC_CHECK_SUCCESS' },
                { id: 'KYC_CHECK_FAILED', name: 'KYC_CHECK_FAILED' },
                { id: 'COMPLY_ADVANTAGE_CHECK_SUCCESS', name: 'COMPLY_ADVANTAGE_CHECK_SUCCESS' },
                { id: 'COMPLY_ADVANTAGE_CHECK_FAILED', name: 'COMPLY_ADVANTAGE_CHECK_FAILED' },
                { id: 'USER_FROM_RESTRICTED_COUNTRY', name: 'USER_FROM_RESTRICTED_COUNTRY' },
                { id: 'KYC_CHECK_IN_PROGRESS', name: 'KYC_CHECK_IN_PROGRESS' },
                { id: 'BLOCKED', name: 'BLOCKED' },
                { id: 'KYC_CHECK_PENDING', name: 'KYC_CHECK_PENDING' },
            ]} />

            <TextInput multiline source="comment" fullWidth />

            <NumberInput source="bonus" />
            <NumberInput source="totalEarnedBonus" />

            <Grid container>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TextField source="id" />
                        <DateField source="createdAt" />
                        <ReferenceField source="userId" reference="users" />
                        <NumberField source="bonus" />
                        <TextField source="locale" />
                        <TextField source="onfidoApplicantId" />
                        <ReferenceField source="preferredAssetId" reference="assets" />

                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TextField source="preferredAssetInvestBonus" />
                        <TextField source="referralCode" />
                        <TextField source="shortCode" />
                        <TextField source="stripeClientId" />
                        <TextField source="timezone" />
                        <NumberField source="totalEarnedBonus" />
                        <DateField source="updatedAt" />
                        <TextField source="userDeviceData" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>

        </SimpleForm>
    </Edit>
);