import { BooleanField, Datagrid, DateField, DateTimeInput, List, SelectInput, TextField, TextInput } from 'react-admin';

const userFilters = [
    <DateTimeInput label="From" source="createdAt@_gte" alwaysOn />,
    <DateTimeInput label="To" source="createdAt@_lte" alwaysOn />,
    <TextInput label="Search by Id" source="id" alwaysOn />,
    <TextInput label="Search by Email" source="email@_like" alwaysOn />,
    <TextInput label="Search by Display Name" source="displayName@_like" alwaysOn />,
    <TextInput label="Search by First Name" source="metadata#_contains@firstName" alwaysOn />,
    <TextInput label="Search by Last Name" source="metadata#_contains@lastName" alwaysOn />,
    <SelectInput label="Search by Default Role" source="defaultRole" alwaysOn choices={[
        { id: 'viewer', name: 'viewer' },
        { id: 'user', name: 'user' },
    ]} />
];

export const UserList = () => (
    <List filters={userFilters} >
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <TextField source="id" />
            <DateField source="avatarUrl" />
            <DateField source="createdAt" />
            <TextField source="defaultRole" />
            <BooleanField source="disabled" />
            <TextField label="First Name" source="metadata.firstName" />
            <TextField label="Last Name" source="metadata.lastName" />
            <TextField source="displayName" />
            <TextField source="email" />
            <DateField source="lastSeen" />
            <TextField source="locale" />
            <TextField source="metadata" />
            <TextField source="newEmail" />
            <TextField source="phoneNumber" />
            <BooleanField source="phoneNumberVerified" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);