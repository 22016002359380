
import { DateField, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';
import { JsonInput } from "react-admin-json-view";

export const CompanyProfileEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '50%' }}>
            <ReferenceInput label="Name" source="assetId" reference="assets">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="country" fullWidth />
            <NumberInput source="marketCapitalization" fullWidth />
            <TextInput source="currency" fullWidth />
            <JsonInput source="data" reactJsonOptions={{
                name: null,
                collapsed: true,
                enableClipboard: false,
                displayDataTypes: false,
            }} />
            <SimpleShowLayout>
                <TextField source="id" fullWidth />
                <DateField source="createdAt" fullWidth />
                <DateField source="updatedAt" fullWidth />
            </SimpleShowLayout>
        </SimpleForm>
    </Edit>
);