import { Create, SimpleForm, TextInput, BooleanInput } from "react-admin";

export const AssetCreate = () => (
    <Create>
        <SimpleForm style={{ width: '50%' }}>
            <BooleanInput source="active" defaultValue={true} />
            <BooleanInput source="fractionable" defaultValue={true} disabled />
            <TextInput source="name" fullWidth />
            <TextInput source="symbol" fullWidth />
            <TextInput source="logo" fullWidth />
            <TextInput source="providerId" defaultValue="deef6b47-b141-4096-a2b0-db4f4fb1af76" disabled fullWidth />
            <TextInput source="exchange" defaultValue="ZORION" disabled fullWidth />
            <TextInput source="marketType" defaultValue="secondary" disabled fullWidth />
        </SimpleForm>
    </Create>
);