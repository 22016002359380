import { BooleanInput, DateField, Edit, NumberInput, ReferenceField, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';

export const AssetEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '50%' }}>
            <BooleanInput source="active" />
            <NumberInput source="sortIndex" />
            <TextInput source="name" fullWidth />
            <TextInput source="fullName" fullWidth />
            <TextInput source="shortName" fullWidth />
            <TextInput source="symbol" fullWidth />
            <TextInput source="logo" fullWidth />

            <SimpleShowLayout>
                <TextField source="id" fullWidth />
                <DateField source="createdAt" fullWidth />
                <TextField source="exchange" fullWidth />
                <TextField source="marketType" fullWidth />
                <ReferenceField source="providerId" reference="providers" fullWidth />
                <DateField source="updatedAt" fullWidth />
            </SimpleShowLayout>
        </SimpleForm>
    </Edit >
);