// in src/MyMenu.js
import { Menu } from 'react-admin';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';

export const CustomMenu = () => (
    <Menu>
        <Menu.ResourceItem name="assets" />
        <Menu.ResourceItem name="companyProfiles" />
        <Menu.ResourceItem name="orders" />
        <Menu.ResourceItem name="transfers" />
        <Menu.ResourceItem name="SPVs" />
        <Menu.ResourceItem name="paymentMethods" />
        <Menu.ResourceItem name="users" />
        <Menu.ResourceItem name="authUserProviders" />
        <Menu.ResourceItem name="userOptions" />
        <Menu.ResourceItem name="authUserRoles" />
        <Menu.ResourceItem name="accounts" />
        <Menu.ResourceItem name="news" />
        <Menu.Item to="/push-notifications" primaryText="Push notifications" leftIcon={<NotificationAddIcon />} />
    </Menu>
);