import { Datagrid, DateField, List, NumberField, ReferenceField, TextField, TextInput, SelectInput, DateTimeInput } from 'react-admin';

const userOptionsFilters = [
    <DateTimeInput label="From" source="createdAt@_gte" alwaysOn />,
    <DateTimeInput label="To" source="createdAt@_lte" alwaysOn />,
    <TextInput label="Search by User Id" source="userId" alwaysOn />,
    <TextInput label="Search by Timezone" source="timezone@_like" alwaysOn />,
    <TextInput label="Search by stripeClient" source="stripeClientId@_like" alwaysOn />,
    <TextInput label="Search by Email" source="user#email@_like" alwaysOn />,
    <SelectInput label="Search by Operating system" source="userDeviceData#_contains@operatingSystem" alwaysOn choices={[
        { id: 'android', name: 'android' },
        { id: 'ios', name: 'ios' },
    ]} />
];

export const UserOptionsList = () => (
    <List filters={userOptionsFilters}>
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <TextField source="id" />
            <DateField source="createdAt" />
            <ReferenceField source="userId" reference="users" />
            <ReferenceField label="Email" reference="users" source="userId">
                <TextField source="email" />
            </ReferenceField>
            <NumberField source="bonus" />
            <NumberField source="totalEarnedBonus" />
            <TextField source="checkStatus" />
            <TextField source="comment" />
            <TextField source="locale" />
            <TextField source="onfidoApplicantId" />
            <ReferenceField source="preferredAssetId" reference="assets" />
            <TextField source="preferredAssetInvestBonus" />
            <TextField source="referralCode" />
            <TextField source="shortCode" />
            <TextField source="stripeClientId" />
            <TextField source="timezone" />
            <DateField source="updatedAt" />
            <TextField source="userDeviceData" />

        </Datagrid>
    </List >
);