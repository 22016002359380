import { CREATE, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'react-admin';
import { upperFirst } from './utils';

type Resource = { name: string }

export const introspectionOptions = {
    include: ["assets, SPVs, orders, assetPricePerShare"],
    operationNames: {
        [CREATE]: (resource: Resource) => `insert${upperFirst(resource.name)}`,
        [UPDATE]: (resource: Resource) => `update${upperFirst(resource.name)}`,
        [UPDATE_MANY]: (resource: Resource) => `update${upperFirst(resource.name)}`,
        [DELETE]: (resource: Resource) => `delete${upperFirst(resource.name)}`,
        [DELETE_MANY]: (resource: Resource) => `delete${upperFirst(resource.name)}`,
    },
}