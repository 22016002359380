import { Datagrid, DateField, List, ReferenceField, SelectInput, TextField, TextInput } from 'react-admin';

const authUserRolesFilters = [
    <TextInput label="Search by User Id" source="userId" alwaysOn />,
    <SelectInput label="Search by Role" source="role" alwaysOn choices={[
        { id: 'viewer', name: 'viewer' },
        { id: 'user', name: 'user' },
    ]} />
];

export const AuthUserRolesList = () => (
    <List filters={authUserRolesFilters}>
        <Datagrid rowClick="edit">
            <DateField source="createdAt" />
            <TextField source="id" />
            <TextField source="role" />
            <ReferenceField source="userId" reference="users" />
        </Datagrid>
    </List>
);