import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import { SimpleForm, TextInput } from "react-admin";

export const PushNotifications = () => {
    const dataProvider = useDataProvider();
    const [error, setError] = useState(String);
    const [successMessages, setSuccessMessages] = useState<string[]>([]);
    const [sending, setSending] = useState(false);

    const onSave = async (values: any) => {
        if (sending) return;
        setSending(true);
        console.log(values);
        setError('');
        setSuccessMessages([]);

        const users = [...values.users.split(',').map((user: string) => user.trim())];

        if (users.length > 100) {
            setError('Max 100 users allowed');
            return;
        }

        const pushNotifications = users.map((userId: string) => {
            return {
                userId: userId,
                status: "NEW",
                body: values.body,
                title: values.title,
                recipientData: {
                    ...(values.redirectTo && { redirectTo: values.redirectTo }),
                    ...(values.assetId && { assetId: values.assetId }),
                },
                type: "ADMIN_MANUAL"
            }
        });

        const batches = splitIntoBatches(pushNotifications, 10);

        console.log(batches);

        batches.forEach(async batch => {
            try {
                await Promise.all(
                    batch.map((pushNotification: any) =>
                        dataProvider.create('pushNotifications', { data: pushNotification })
                    )
                );
                setSuccessMessages(successMessages => [...successMessages, batch.length + ' Push notifications sent successfully!']);

            } catch (error: any) {
                setError(error.errors[0].message);
            } finally {
                setSending(false);
            }
        });

    }

    const splitIntoBatches = (arr: Array<any>, batchSize: number) => {
        let batches = [];
        for (let i = 0; i < arr.length; i += batchSize) {
            let batch = arr.slice(i, i + batchSize);
            batches.push(batch);
        }
        return batches;
    }

    const validatePushNotifications = (values: any) => {
        const errors: any = {};

        if (values.redirectTo === "ASSET") {
            if (!values.assetId) {
                errors.assetId = 'Asset id is required';
            }
        }

        return errors;
    }

    return (
        <SimpleForm onSubmit={onSave} validate={validatePushNotifications}>
            <TextInput source="title" required />
            <TextInput source="body" required />
            <TextInput source="redirectTo" />
            <TextInput source="assetId" />
            <TextInput source="users" required label="users seperated with: ',' for example: userId, userId, userId (max 100 ids)" multiline fullWidth />


            {successMessages.map((successMessage, i) => {
                return (
                    <p key={i}>
                        {successMessage}
                    </p>
                );
            })}

            <br></br>
            {error &&
                <p>
                    error: {error}
                </p>
            }

        </SimpleForm>
    );
}