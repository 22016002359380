import { BooleanInput, DateField, DateInput, Edit, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';

export const SpvEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '50%' }}>
            <BooleanInput source="active" />
            <ReferenceInput label="Name" source="assetId" reference="assets" perPage={100}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" fullWidth />

            <NumberInput source="carryInterest" fullWidth />
            <NumberInput source="commission" fullWidth />
            <DateInput source="endDate" fullWidth />
            <TextInput source="legalAddress" fullWidth />
            <NumberInput source="managementFee" fullWidth />
            <NumberInput source="maxInvestment" fullWidth />
            <NumberInput source="minInvestment" fullWidth />
            <DateInput source="startDate" fullWidth />


            <SimpleShowLayout>
                <NumberField source="activeNumberShares" fullWidth />
                <NumberField source="totalAllocation" fullWidth />
                <NumberField source="totalNumberSharesAsset" fullWidth />
                <TextField source="id" fullWidth />
                <DateField source="createdAt" fullWidth />
                <DateField source="updatedAt" fullWidth />
            </SimpleShowLayout>
        </SimpleForm>

    </Edit>
);