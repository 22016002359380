import Grid from '@mui/material/Grid/Grid';
import { DateField, Edit, NumberField, ReferenceField, SimpleForm, SimpleShowLayout, TextField } from 'react-admin';

export const TransferEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '50%' }}>
            <Grid container>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <ReferenceField source="accountId" reference="accounts" />
                        <ReferenceField label="Payment Method" reference="paymentMethods" source="paymentMethodId">
                            <TextField source="name" />
                        </ReferenceField>
                        <NumberField source="amount" />
                        <TextField source="bankId" />
                        <TextField source="chargeId" />
                        <DateField source="createdAt" />
                        <TextField source="currency" />
                        <TextField source="direction" />
                        <TextField source="error" />
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <NumberField source="fee" />
                        <TextField source="id" />
                        <TextField source="intentId" />
                        <TextField source="key" />
                        <ReferenceField source="orderId" reference="orders" />
                        <TextField source="provider" />
                        <ReferenceField source="requestId" reference="requests" />
                        <NumberField source="requestedAmount" />
                        <TextField source="status" />
                        <DateField source="updatedAt" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);