import { BooleanInput, Create, DateInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const SpvCreate = () => (
    <Create>
        <SimpleForm style={{ width: '50%' }}>
            <BooleanInput source="active" />
            <ReferenceInput label="Name" source="assetId" reference="assets" perPage={100}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" fullWidth />
            <NumberInput source="activeNumberShares" fullWidth />
            <NumberInput source="carryInterest" fullWidth />
            <NumberInput source="commission" fullWidth />
            <DateInput source="endDate" fullWidth />
            <TextInput source="legalAddress" fullWidth />
            <NumberInput source="managementFee" fullWidth />
            <NumberInput source="maxInvestment" fullWidth />
            <NumberInput source="minInvestment" fullWidth />
            <DateInput source="startDate" fullWidth />
            <NumberInput source="totalAllocation" fullWidth />
            <NumberInput source="totalNumberSharesAsset" fullWidth />
        </SimpleForm>
    </Create>
);