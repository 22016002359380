import { useState, useEffect } from 'react';
import { Route } from "react-router-dom";
import buildHasuraProvider from 'ra-data-hasura';
import { Admin, CustomRoutes, DataProvider, Resource } from 'react-admin';
import { AssetList } from './asset/AssetList';
import { UserList } from './user/UserList';
import { AssetEdit } from './asset/AssetEdit';
import { OrderList } from './order/OrderList';
import { OrderEdit } from './order/OrderEdit';
import { AssetCreate } from './asset/AssetCreate';
import './App.css';
import { introspectionOptions } from './utils/introspection';
import { apolloClient } from './utils/apolloClient';
import { authProvider } from './utils/authProvider';
import { UserEdit } from './user/UserEdit';
import { SpvEdit } from './spv/SpvEdit';
import { SpvList } from './spv/SpvList';
import { AccountList } from './account/AccountList';
import { CompanyProfileList } from './companyProfile/CompanyProfileList';
import { CompanyProfileEdit } from './companyProfile/CompanyProfileEdit';
import { AccountEdit } from './account/AccountEdit';
import Login from './login/Login';
import { EnvDisplay } from './env/EnvDisplay';
import { SpvCreate } from './spv/SpvCreate';
import { TransferList } from './transfer/TransferList';
import { CustomLayout } from './layout/CustomLayout';
import { AuthuserproviderList } from './authUserProviders/AuthUserProvidersList';
import { UserOptionsList } from './userOptions/UserOptionsList';
import { PushNotifications } from './pushNotifications/PushNotifications';
import { AuthUserRolesList } from './authUserRoles/AuthUserRolesList';
import { AuthUserRolesEdit } from './authUserRoles/AuthUserRolesEdit';
import { UserOptionsEdit } from './userOptions/UserOptionsEdit';
import { NewsList } from './news/NewsList';
import { NewsEdit } from './news/NewsEdit';
import { NewsCreate } from './news/NewsCreate';
import { TransferEdit } from './transfer/TransferEdit';
import { PaymentmethodList } from './paymentMethod/PaymentMethodList';
import { PaymentMethodEdit } from './paymentMethod/PaymentMethodEdit';
import { CompanyProfileCreate } from './companyProfile/CompanyProfileCreate';
import { createAggregationQuery } from './utils/utils';

const App = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider<string> | null>(null);

  const client = apolloClient;

  useEffect(() => {
    const buildDataProvider = async () => {

      const _dataProvider = await buildHasuraProvider(
        {
          client,
          introspection: introspectionOptions,
        },
        {
          aggregateFieldName(resourceName) {
            return createAggregationQuery(resourceName);
          },
        },
      )
      setDataProvider(() => _dataProvider)
    }
    buildDataProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  return (
    <>

      <Admin authProvider={authProvider} dataProvider={dataProvider} loginPage={Login} layout={CustomLayout}>

        <Resource
          name="assets"
          create={AssetCreate}
          edit={AssetEdit}
          list={AssetList}
        />

        <Resource
          name="companyProfiles"
          create={CompanyProfileCreate}
          edit={CompanyProfileEdit}
          list={CompanyProfileList}
        />

        <Resource
          name="orders"
          edit={OrderEdit}
          list={OrderList}
        />
        <Resource
          name="transfers"
          edit={TransferEdit}
          list={TransferList}
        />
        <Resource
          name="SPVs"
          create={SpvCreate}
          edit={SpvEdit}
          list={SpvList}
        />
        <Resource
          name="paymentMethods"
          edit={PaymentMethodEdit}
          list={PaymentmethodList}
        />
        <Resource
          name="users"
          edit={UserEdit}
          list={UserList}
        />
        <Resource
          name="authUserProviders"
          list={AuthuserproviderList}
        />
        <Resource
          name="userOptions"
          edit={UserOptionsEdit}
          list={UserOptionsList}
        />
        <Resource
          name="authUserRoles"
          edit={AuthUserRolesEdit}
          list={AuthUserRolesList}
        />
        <Resource
          name="accounts"
          edit={AccountEdit}
          list={AccountList}
        />
        <Resource
          name="news"
          create={NewsCreate}
          edit={NewsEdit}
          list={NewsList}
        />
        <CustomRoutes>
          <Route path="/push-notifications" element={<PushNotifications />} />
        </CustomRoutes>

      </Admin >
      <EnvDisplay />
    </>
  )
};

export default App;