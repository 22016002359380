import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache();

enum Environment {
    Dev = 'dev',
    Staging = 'staging',
    Production = 'production',
}

const httpLink = createHttpLink({
    uri: String(localStorage.getItem('env')) === Environment['Dev'] ? process.env.REACT_APP_HASURA_DEV_URL
        : String(localStorage.getItem('env')) === Environment['Staging'] ? process.env.REACT_APP_HASURA_STAGING_URL
            : process.env.REACT_APP_HASURA_PRODUCTION_URL
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            'Content-Type': 'application/json',
            ...(token ? {
                authorization: `Bearer ${token}`
            } : {})
        }

    }
});

export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
    },
});