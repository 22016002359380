import { Datagrid, DateField, List, ReferenceField, TextField, TextInput } from 'react-admin';

const accountFilters = [
    <TextInput label="Search by Id" source="id" alwaysOn />,
];

export const AccountList = () => (
    <List filters={accountFilters} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="userId" reference="users">
                <TextField source="displayName" />
            </ReferenceField>
            <TextField source="status" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);
