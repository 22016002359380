import { UrlField, useRecordContext } from 'react-admin';

export const AgreementLink = () => {
    const record = useRecordContext();

    const onAgreementClick = (event: any) => {
        event.preventDefault();

        const token = localStorage.getItem('token');

        function showInOtherTab(blob: any) {
            const url = window.URL.createObjectURL(blob);
            window.open(url);
        }

        fetch(`https://storage.zorion.asia/v1/files/${record.agreementId}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
            .then(response => response.blob().then((blob: any) => showInOtherTab(blob)))
    }

    return (
        <>
            <UrlField source="agreementId" onClick={onAgreementClick} />
        </>

    );
}