import { BooleanField, Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';

export const SpvList = () => (
    <List>
        <Datagrid rowClick="edit">
            <BooleanField source="active" />
            <TextField source="id" />
            <NumberField source="activeNumberShares" />
            <ReferenceField source="assetId" reference="assets" >
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="carryInterest" />
            <NumberField source="commission" />
            <DateField source="createdAt" />
            <TextField source="legalAddress" />
            <NumberField source="managementFee" />
            <NumberField source="maxInvestment" />
            <NumberField source="minInvestment" />
            <TextField source="name" />
            <NumberField source="totalAllocation" />
            <NumberField source="totalNumberSharesAsset" />
            <DateField source="startDate" />
            <TextField source="endDate" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List >
);