import Grid from '@mui/material/Grid/Grid';
import { BooleanInput, Create, DateInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const NewsCreate = () => (
    <Create>
        <SimpleForm style={{ width: '100%' }}>

            <Grid container>
                <Grid item xs={6}>

                    <SelectInput source="template" fullWidth choices={[
                        { id: 'SHORT_SUMMARY', name: 'SHORT_SUMMARY' },
                        { id: 'SHORT_SUMMARY_IMAGE', name: 'SHORT_SUMMARY_IMAGE' },
                        { id: 'MEDIUM_SUMMARY', name: 'MEDIUM_SUMMARY' },
                        { id: 'MEDIUM_SUMMARY_IMAGE', name: 'MEDIUM_SUMMARY_IMAGE' },
                        { id: 'NUMBER', name: 'NUMBER' },
                        { id: 'NUMBER_IMAGE', name: 'NUMBER_IMAGE' },
                        { id: 'QUOTE', name: 'QUOTE' },
                        { id: 'QUOTE_IMAGE', name: 'QUOTE_IMAGE' },
                    ]} required />

                    <SelectInput source="colorScheme" fullWidth choices={[
                        { id: 'PINK', name: 'PINK' },
                        { id: 'VIOLET', name: 'VIOLET' },
                        { id: 'BLUE', name: 'BLUE' },
                        { id: 'GREEN', name: 'GREEN' },
                        { id: 'YELLOW', name: 'YELLOW' },
                        { id: 'RED', name: 'RED' },
                        { id: 'NAVY', name: 'NAVY' },
                        { id: 'CREAM', name: 'CREAM' },
                    ]} required />

                    <DateInput source="date" fullWidth required />

                    <ReferenceInput label="Name" source="assetId" reference="assets">
                        <SelectInput optionText="name" fullWidth required />
                    </ReferenceInput>


                    <TextInput source="headline" multiline fullWidth maxRows={5} required />
                    <TextInput source="content" multiline fullWidth maxRows={5} required />
                    <TextInput source="longSummary" multiline fullWidth maxRows={5} />
                    <TextInput source="mediumSummary" multiline fullWidth maxRows={5} />
                    <TextInput source="shortSummary" multiline fullWidth maxRows={5} required />
                    <TextInput source="quote" multiline fullWidth maxRows={5} />
                    <TextInput source="quoteAuthor" fullWidth />

                    <BooleanInput source="isMedia" />

                    <TextInput source="companyName" fullWidth />

                    <NumberInput source="initialGeneralShares" />
                    <NumberInput source="initialInstagramStoryShares" />
                    <NumberInput source="initialLikes" required />

                    <TextInput source="image" fullWidth required /><br />

                    <TextInput source="source" fullWidth required /> <br />
                    <TextInput source="url" fullWidth required />

                </Grid>

            </Grid>

        </SimpleForm>
    </Create >
);