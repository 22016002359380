import { Datagrid, DateField, List, NumberField, ReferenceField, TextField, TextInput } from 'react-admin';


const transferFilters = [
    <TextInput label="Search by Id" source="id" alwaysOn />,
    <TextInput label="Search by Order Id" source="orderId" alwaysOn />,
];

export const TransferList = () => (
    <List filters={transferFilters} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <ReferenceField source="accountId" reference="accounts" />
            <ReferenceField label="Payment Method" reference="paymentMethods" source="paymentMethodId">
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="amount" />
            <TextField source="bankId" />
            <TextField source="chargeId" />
            <DateField source="createdAt" />
            <TextField source="currency" />
            <TextField source="direction" />
            <TextField source="error" />
            <NumberField source="fee" />
            <TextField source="id" />
            <TextField source="intentId" />
            <TextField source="key" />
            <ReferenceField source="orderId" reference="orders" />
            <TextField source="provider" />
            <ReferenceField source="requestId" reference="requests" />
            <NumberField source="requestedAmount" />
            <TextField source="status" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);