
import { Datagrid, DateField, DateTimeInput, List, NumberField, ReferenceField, ReferenceManyField, SelectInput, SingleFieldList, TextField, TextInput } from 'react-admin';


const orderFilters = [
    <DateTimeInput label="From" source="createdAt@_gte" alwaysOn />,
    <DateTimeInput label="To" source="createdAt@_lte" alwaysOn />,
    <TextInput label="Search by Id" source="id" alwaysOn />,
    <TextInput label="Search by Email" source="account#user#email@_like" alwaysOn />,
    <SelectInput label="Search by Side" source="side" alwaysOn choices={[
        { id: 'BUY', name: 'BUY' },
        { id: 'REFUND', name: 'REFUND' },
        { id: 'REINVEST', name: 'REINVEST' },
        { id: 'BUY_BACK', name: 'BUY_BACK' },
    ]} />,
    <SelectInput label="Search by Status" source="status" alwaysOn choices={[
        { id: 'FILLED', name: 'FILLED' },
        { id: 'DRAFT', name: 'DRAFT' },
        { id: 'PENDING', name: 'PENDING' },
        { id: 'DECLINED', name: 'DECLINED' },
        { id: 'ACCEPTED', name: 'ACCEPTED' },
        { id: 'REFUNDED', name: 'REFUNDED' },
        { id: 'BUY_BACKED', name: 'BUY_BACKED' },
        { id: 'CONFIRMED', name: 'CONFIRMED' },
        { id: 'REJECTED', name: 'REJECTED' },
        { id: 'CANCELED', name: 'CANCELED' },
        { id: 'REINVESTED', name: 'REINVESTED' },
    ]} />,
    <TextInput label="Search by SPV Name" source="SPV#name@_like" alwaysOn />,
    <TextInput label="Search by Comment" source="notes@_like" alwaysOn />,

];



export const OrderList = () => (
    <List filters={orderFilters} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" />
            <ReferenceField source="accountId" reference="accounts" />
            <DateField source="createdAt" label="Date" />
            <DateField source="createdAt" label="Time" showTime showDate={false} />
            <TextField source="status" />
            <TextField source="side" />

            <ReferenceManyField label="Provider" target="orderId" reference="transfers">
                <SingleFieldList>
                    <TextField source="provider" />
                </SingleFieldList>
            </ReferenceManyField>

            <ReferenceField label="First Name" reference="accounts" source="accountId">
                <ReferenceField label="First Name" reference="users" source="userId">
                    <TextField source="metadata.firstName" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField label="Last Name" reference="accounts" source="accountId">
                <ReferenceField label="Last Name" reference="users" source="userId">
                    <TextField source="metadata.lastName" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField label="Email" reference="accounts" source="accountId">
                <ReferenceField label="Email" reference="users" source="userId">
                    <TextField source="email" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField label="Country of residence" reference="accounts" source="accountId">
                <ReferenceField label="Country of residence" reference="users" source="userId">
                    <TextField source="metadata.citizenship.name" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField label="SPV Name" source="spvId" reference="SPVs">
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField label="Asset Name" source="spvId" reference="SPVs">
                <ReferenceField label="Asset Name" reference="assets" source="assetId">
                    <TextField source="name" />
                </ReferenceField>
            </ReferenceField>

            <NumberField label="Notional" source="notional" />
            <NumberField label="Bonus used" source="bonusUsed" />
            <NumberField source="commission" />
            <NumberField label="Overall Charged" source="overallCharged" />

            <NumberField label="Number of shares_Asset" source="amount" />
            <NumberField label="Number of shares_SPV" source="amountSharesOfSPV" />

            <NumberField label="Remaining allocation available, USD" />

            <ReferenceField label="Max Investment" source="spvId" reference="SPVs">
                <TextField source="maxInvestment" />
            </ReferenceField>

            <ReferenceField label="Min Investment" source="spvId" reference="SPVs">
                <TextField source="minInvestment" />
            </ReferenceField>

            <TextField source="managementFee" />

            <ReferenceField source="relatedOrderId" reference="orders" />

            <DateField source="updatedAt" />

            <ReferenceField source="agreementId" reference="files" />

        </Datagrid>
    </List>
);