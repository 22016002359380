import Grid from '@mui/material/Grid/Grid';
import { BooleanField, BooleanInput, DateField, Edit, SelectInput, SimpleForm, SimpleShowLayout, TextField, WrapperField } from 'react-admin';
import { OrdersForUser } from './OrdersForUser';

export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <div style={{ width: '50%' }}>
                <BooleanInput source="disabled" />
                <SelectInput label="Default Role" source="defaultRole" alwaysOn choices={[
                    { id: 'viewer', name: 'viewer' },
                    { id: 'user', name: 'user' },
                ]} />
                <Grid container>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <TextField source="id" fullWidth />
                            <TextField source="locale" fullWidth />
                            <TextField source="displayName" fullWidth />
                            <TextField source="email" fullWidth />
                            <BooleanField source="isAnonymous" />
                            <DateField source="createdAt" fullWidth />
                            <DateField source="updatedAt" fullWidth />
                            <DateField source="lastSeen" fullWidth />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <TextField source="avatarUrl" fullWidth />
                            <TextField source="metadata" fullWidth />
                            <TextField source="newEmail" fullWidth />

                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </div>
            <div style={{ width: '100%' }}>
                <SimpleShowLayout>
                    <WrapperField label="orders" fullWidth>
                        <OrdersForUser />
                    </WrapperField>
                </SimpleShowLayout>
            </div>
        </SimpleForm>

    </Edit>
);