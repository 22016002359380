import { BooleanInput, DateField, Edit, NumberInput, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';

export const PaymentMethodEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '50%' }}>

            <BooleanInput source="active" />
            <TextInput source="name" />
            <TextInput source="picture" />
            <TextInput source="description" />
            <NumberInput source="fixedFee" />
            <NumberInput source="commission" />
            <NumberInput source="max" />
            <NumberInput source="min" />

            <SimpleShowLayout>
                <TextField source="id" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <TextField source="paymentProvider" />
                <TextField source="type" />
            </SimpleShowLayout>



        </SimpleForm>
    </Edit>
);