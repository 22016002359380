import Grid from '@mui/material/Grid/Grid';
import { BooleanField, BooleanInput, DateField, DateInput, Edit, FunctionField, NumberInput, ReferenceField, ReferenceInput, SelectInput, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';

export const NewsEdit = () => (
    <Edit>
        <SimpleForm style={{ width: '100%' }}>

            <Grid container>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <SelectInput source="template" fullWidth choices={[
                            { id: 'SHORT_SUMMARY', name: 'SHORT_SUMMARY' },
                            { id: 'SHORT_SUMMARY_IMAGE', name: 'SHORT_SUMMARY_IMAGE' },
                            { id: 'MEDIUM_SUMMARY', name: 'MEDIUM_SUMMARY' },
                            { id: 'MEDIUM_SUMMARY_IMAGE', name: 'MEDIUM_SUMMARY_IMAGE' },
                            { id: 'NUMBER', name: 'NUMBER' },
                            { id: 'NUMBER_IMAGE', name: 'NUMBER_IMAGE' },
                            { id: 'QUOTE', name: 'QUOTE' },
                            { id: 'QUOTE_IMAGE', name: 'QUOTE_IMAGE' },
                        ]} required />

                        <SelectInput source="colorScheme" fullWidth choices={[
                            { id: 'PINK', name: 'PINK' },
                            { id: 'VIOLET', name: 'VIOLET' },
                            { id: 'BLUE', name: 'BLUE' },
                            { id: 'GREEN', name: 'GREEN' },
                            { id: 'YELLOW', name: 'YELLOW' },
                            { id: 'RED', name: 'RED' },
                            { id: 'NAVY', name: 'NAVY' },
                            { id: 'CREAM', name: 'CREAM' },
                        ]} required />

                        <ReferenceInput label="Name" source="assetId" reference="assets">
                            <SelectInput optionText="name" required />
                        </ReferenceInput>


                        <TextInput source="headline" multiline fullWidth maxRows={5} required />
                        <TextInput source="content" multiline fullWidth maxRows={5} required />
                        <TextInput source="longSummary" multiline fullWidth maxRows={5} />
                        <TextInput source="mediumSummary" multiline fullWidth maxRows={5} />
                        <TextInput source="shortSummary" multiline fullWidth maxRows={5} required />
                        <TextInput source="quote" multiline fullWidth maxRows={5} />
                        <TextInput source="quoteAuthor" fullWidth />
                        <BooleanInput source="isMedia" />

                    </SimpleShowLayout>

                </Grid>
                <Grid item xs={6}>
                    <SimpleShowLayout>

                        <TextField source="id" />
                        <ReferenceField source="assetId" reference="assets" />

                        <DateField source="createdAt" />
                        <DateField source="updatedAt" />
                        <DateInput source="date" fullWidth />

                        <TextInput source="companyName" fullWidth />
                        <BooleanField source="companyNameMatch" />

                        <NumberInput source="computed_general_shares" fullWidth />
                        <NumberInput source="computed_instagram_story_shares" fullWidth />
                        <NumberInput source="computed_likes" fullWidth />

                        <NumberInput source="initialGeneralShares" fullWidth />
                        <NumberInput source="initialInstagramStoryShares" fullWidth />
                        <NumberInput source="initialLikes" fullWidth required />

                        <TextInput source="image" fullWidth required />
                        <TextInput source="source" fullWidth required />
                        <TextInput source="url" fullWidth required />

                        <TextField source="number" />
                        <FunctionField label="numberDescription" render={(record: { numberDescription: string; }) => record.numberDescription ? record.numberDescription.substring(0, 20) + '...' : ''} />

                        <BooleanField source="restrictedTopicIndicator" />

                        <TextField source="svg" />

                    </SimpleShowLayout>
                </Grid>
            </Grid>

        </SimpleForm>
    </Edit >
);