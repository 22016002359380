import { Datagrid, DateField, List, NumberField, ReferenceField, TextField, TextInput } from 'react-admin';
import { JsonField } from "react-admin-json-view";

const companyProfilesFIlters = [
    <TextInput label="Search by Asset Id" source="assetId" alwaysOn />,
];

export const CompanyProfileList = () => (
    <List filters={companyProfilesFIlters}>
        <Datagrid rowClick="edit" >
            <ReferenceField source="assetId" reference="assets" />
            <TextField source="country" />
            <DateField source="createdAt" />
            <TextField source="currency" />
            <JsonField source="data" reactJsonOptions={{
                name: null,
                collapsed: true,
                enableClipboard: false,
                displayDataTypes: false,
            }} />
            <TextField source="id" />
            <NumberField source="marketCapitalization" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);